(function ($) {
  Drupal.behaviors.basicSelectFormatterV1 = {
    attach: function (context) {
      selectBox = $('.basic-select', context);

      selectBox.change(function () {
        selected = $(this).find(':selected');
        if (selected.data('url')) {
          window.location = selected.data('url');
        }
      });
    }
  };
})(jQuery);
